<template>
  <div class="wrap">
    <h1>{{ msg }}</h1>
    <div>
     <ul>
       <li>
          {{ totals }}
       </li>
     </ul> 
    
     
    </div>
    <ul class="calendar">
      <li  v-for="day in days"  v-bind:key="day" class="day" :class="[day.status == 1 ? 'success' : '']">
        <span><button @click="dayСlick(day)" >{{day.day_value}}</button></span>
      </li>
    </ul>

    <div>
      {{rand_val}}
    </div>
    
    
    <p>
      <button @click="getRandomValue" type="button" class="btn btn-primary">Get Random Day Value</button>
    </p>
    <p>
      <button @click="setRandomValue" type="button" class="btn btn-warning" :class="[rand_val == null ? 'd-none' : '']" >Set Day Value</button>
    </p>
    
    
  

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Calendar',
  props: {
    msg: String,
  },
  data() {
    return {
      days: null,
      totals: null,
      rand_val: null
    };
  },

  methods: {
    async dayСlick(day){
        let message = '';
        //let conf_message = '';
        if(day.status == 1){
          message = 'Удалить занчение?';
          //conf_message = 'Удалено! ' + day.day_number + ' ' + day.def_value;
        }else{
          message = 'Добавить занчение?';
          //conf_message = 'Добавлено! ' + day.day_number;
        }
        if(confirm(message)){
          
            if(day.status == 1){
              //alert(conf_message);
            
                await axios.post('https://100times.regplant.ru/api/del-val', {
                    day_value: day.day_value
                  }, {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }).then(function (response) {
                    if(response.data.success == true){
                      alert('Delete');
                      window.location.reload();
                    }
                    console.log(response);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });  


            }else{
              await axios.post('https://100times.regplant.ru/api/set-val', {
                    day_value: day.day_value
                  }, {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }).then(function (response) {
                    if(response.data.success == true){
                      alert('Save');
                      window.location.reload();
                    }
                    console.log(response);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
            }

          
        }
      
        
    },
    async getRandomValue(){
      await axios.get('https://100times.regplant.ru/api/get-rand-val').then((response) => {
          this.rand_val = response.data
      });
    },
    async setRandomValue(){

      let day_value = this.rand_val.day_value;

      

      await axios.post('https://100times.regplant.ru/api/set-val', {
        day_value: day_value
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (response) {
        if(response.data.success == true){
          alert('Save');
          window.location.reload();
        }
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

      
      // await axios.get(`https://100times.regplant.ru/api/set-rand-val/?day_value=${day_value}`).then((response) => {
      //     //this.rand_val = response.data
      //     if(!response.data.error){
      //       alert('Save');
      //       window.location.reload();
      //     }else{
      //       alert('Error');
      //     }
          
      // });
    
    }
  },
  mounted() {
    axios
      .get('https://100times.regplant.ru/api/schedule')
     // .then(response => (this.info = response));
      //.then(response => (this.days = response.data))
      .then((response) => {
        //console.log(response)
         this.days = response.data.days;
         this.totals = response.data.totals;
          //this.totals.precent =  Math.round((this.totals.colected / this.totals.goal)*100) ;
        
        /*for (const day in response.data) {
          let day_value =  response.data[day]['day_value'];
          console.log(day_value);
        }*/
        
      })
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}

.calendar{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
     
}


.day{
  flex: 1 1 10%;
   span{
    text-align: center;
      display: block;
      padding: 12px;
   }
   button{
    background: #fff;
    border:1px solid #ccc;
    &:hover{
      background-color: #fff;
    }
   }
  @media (max-width: 801px) {
       flex: 1 1 20%;
  }
}

.day.success{
  background-color: green;
  color:#fff;
}

.wrap{
  width: 800px;
  margin: 0 auto;
}

@media (max-width: 801px) {
  .wrap{
    width: 100%;
  }
  
}


a {
  color: #42b983;
}
</style>
